import React, { Component } from 'react';
import Input from 'components/common/input';
import Toast from 'components/common/toast';
import Button from 'components/common/button';
import Loading from 'components/common/loading';
import api from 'helpers/api';
import auth from 'helpers/auth';
import history from 'helpers/history';

class Invitation extends Component {
  constructor() {
    super();
    this.state = {
      email: false,
      phone: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      code: '',
      error: false,
      userState: false,
      requestedVerification: false,
      loading: true,
      loadingVerification: false,
      loadingAccount: false,
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);

    this.handleSubmitPhone = this.handleSubmitPhone.bind(this);
    this.handleSubmitCode = this.handleSubmitCode.bind(this);

    this.handleSignUpSuccess = this.handleSignUpSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleFirebaseError = this.handleFirebaseError.bind(this);
    this.handleWrongPassword = this.handleWrongPassword.bind(this);
  }

  componentDidMount() {
    document.title = 'Invitation | Flamingo Insights';
    this.verifyToken();
    window.recaptchaVerifier = new auth.RecaptchaVerifier(auth.getAuth(), 'recaptchaContainer', {});
  }

  // Networking

  verifyToken() {
    const token = this.props.match.params.token;
    return api.post('/sign-up/verify', { token }, {}, false)
      .then((res) => this.setState({ loading: false, userState: res.data.data.status, phone: res.data.data.phone, email: res.data.data.email }))
      .catch((e) => {
        const errorCode = this.handleError(e);
        if (errorCode === 'invitation_expired' || errorCode === 'invitation_not_found') {
          window.location.replace('https://insights.flamingo.co.nz/');
        } else {
          this.setState({ fatalError: errorCode, loading: false })
        }
      });
  }

  handleSubmitPhone(e) {
    if (e) {
      e.preventDefault();
    }

    const phone = this.state.phone.replace(/[^0-9+]/g,'');
    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const userState = this.state.userState;

    if (!phone || phone.charAt(0) !== '+') {
      this.setState({ error: 'Invalid Phone' });
      return;
    }
    if (confirmPassword !== password) {
      this.setState({ error: 'Passwords do not match' });
      return;
    }
    if (!password) {
      this.setState({ error: 'Invalid Password' });
      return;
    }
    if (!firstName && userState === 'NEW') {
      this.setState({ error: 'Invalid First Name' });
      return;
    }
    if (!lastName && userState === 'NEW') {
      this.setState({ error: 'Invalid Last Name' });
      return;
    }

    this.setState({ loadingVerification: true, error: false });
    const verifier = window.recaptchaVerifier;
    auth.loginWithPhoneNumber(phone, verifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        this.setState({ requestedVerification: true });
      }).catch(this.handleFirebaseError);
  }

  handleSubmitCode(e) {
    if (e) {
      e.preventDefault();
    }

    const email = this.state.email;
    const password = this.state.password;
    const code = this.state.code;
    const confirmationResult = window.confirmationResult;

    this.setState({ loadingAccount: true, error: false });

    confirmationResult.confirm(code)
      .then((result) => {
        const credential = auth.credentialFromEmail(email, password);
        return auth.joinWithCredential(credential)
          .then(() => auth.loginWithCredential(credential).catch(this.handleWrongPassword))
          .then(() => auth.setPassword(password))
          .catch(() => auth.loginWithCredential(credential).catch(this.handleWrongPassword));
      })
      .then(this.handleSignUpSuccess)
      .catch(this.handleFirebaseError);
  }

  handleSignUpSuccess() {
    const token = this.props.match.params.token;
    const firstName = this.state.firstName.length > 0 ? this.state.firstName : undefined;
    const lastName = this.state.lastName.length > 0 ? this.state.lastName : undefined;
    return api.post('/sign-up/setup', { token, firstName, lastName })
      .then(() => window.location.replace('https://insights.flamingo.co.nz/'))
      .catch((e) => this.setState({ error: this.handleError(e), loadingVerification: false, loadingAccount: false, loading: false }));
  }

  // Inputs

  handlePhoneChange(phone) {
    this.setState({ phone, error: false });
  }

  handlePasswordChange(password) {
    this.setState({ password, error: false });
  }

  handleConfirmPasswordChange(confirmPassword) {
    this.setState({ confirmPassword, error: false });
  }

  handleCodeChange(code) {
    this.setState({ code, error: false });
  }

  handleFirstNameChange(firstName) {
    this.setState({ firstName, error: false });
  }

  handleLastNameChange(lastName) {
    this.setState({ lastName, error: false });
  }

  // Errors

  handleFirebaseError(e) {
    if (e.code === 'auth/email-already-in-use') {
      history.push('/login');
    } else {
      this.setState({ loading: false, loadingVerification: false, loadingAccount: false, error: e.message })
    }
  }

  handleWrongPassword(e) {
    if (e.code === 'auth/wrong-password') {
      this.setState({ loading: false, requestedVerification: false, loadingAccount: false, loadingVerification: false, error: 'Wrong Password' });
    }
  }

  handleError(e) {
    return window.access(() => e.response.data.error) ? e.response.data.error : 'Something went wrong';
  }

  // Render

  renderState() {
    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.fatalError) {
      return <p className="fm-invitation-error">{ this.state.fatalError }</p>;
    }

    if (this.state.requestedVerification) {
      return (
        <form onSubmit={this.handleSubmitCode}>
          <p className="fm-text">Please enter the verification code sent to { this.state.phone }. If you do not receive a code within 60 seconds please try again.</p>
          <Input label="Verification Code" onChange={this.handleCodeChange} value={this.state.code} disabled={this.state.loadingAccount} />
          <Button loading={this.state.loadingAccount}>Verify Code</Button>
        </form>
      );
    }

    return (
      <form onSubmit={this.handleSubmitPhone}>
        <p className="fm-text">Welcome to the Flamingo Insights Platform. Please enter the following information to continue.</p>
        { this.state.userState === 'NEW' && <Input label="First Name" onChange={this.handleFirstNameChange} value={this.state.firstName} disabled={this.state.loadingVerification} /> }
        { this.state.userState === 'NEW' && <Input label="Last Name" onChange={this.handleLastNameChange} value={this.state.lastName} disabled={this.state.loadingVerification} /> }
        <Input label="Verify Phone Number" onChange={this.handlePhoneChange} value={this.state.phone} disabled={this.state.loadingVerification} placeholder="International Format Eg. +64 22" />
        <Input label="Create Password" onChange={this.handlePasswordChange} value={this.state.password} disabled={this.state.loadingVerification} type="password" />
        <Input label="Confirm Password" onChange={this.handleConfirmPasswordChange} value={this.state.confirmPassword} disabled={this.state.loadingVerification} type="password" />
        <Button loading={this.state.loadingVerification}>{ this.state.loadingVerification ? 'Loading...' : 'Send Phone Code' }</Button>
      </form>
    );
  }

  render() {
    return (
      <div className="fm-invitation">
        <div className="fm-invitation-container">
          <div className="fm-invitation-logo"></div>
          <p className="fm-invitation-title">Insights Platform</p>
          { this.renderState() }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
        { !this.state.requestedVerification && <div id="recaptchaContainer"></div> }
      </div>
    );
  }
}

export default Invitation;
