import React, { useEffect } from 'react';

const Platform = () => {
  useEffect(() => {
    window.location.replace('https://insights.flamingo.co.nz/');
  }, []);
  return null;
};

export default Platform;
